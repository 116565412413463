<mat-spinner *ngIf="showSpinner == true" color="warn" mode="indeterminate" style="margin: 0 auto !important;">
                        
</mat-spinner>

<h2>Amazon Connect Settings</h2> <br/>

<form class="example-form" name="myform" (ngSubmit)="updateSettings(myform.value)" [formGroup]="myform">

    <mat-form-field class="example-full-width">
      <input type="text" class="input" matInput placeholder="Instance Description" formControlName="description" required #description>
    </mat-form-field>
     
    <br />

    <mat-form-field class="example-full-width">
        <input type="text" class="input" matInput placeholder="Instance ID" formControlName="instanceId" required #instanceId>
    </mat-form-field>

    <br />

    <mat-card class="result" style="margin-top: 10px; padding:0px !important;">
      <mat-card-content>
      <p>Tasks Creation Enabled</p> 
        <section class="example-section">

          <mat-slide-toggle 
          formControlName="tasksCreationEnabled" 
          [(ngModel)]="isSlideToggleChecked" 
          name="tasksCreationEnabled" 
          class="example-margin">
          </mat-slide-toggle>

        </section>
      </mat-card-content>
    </mat-card>

     <div class="button-row">
            <button mat-stroked-button [disabled]='!myform.valid'>Save</button>
    </div>

</form>