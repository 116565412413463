export class AmazonConnectContactFlow {
    key: number;
    name:string;
    lineOfBusiness: number;
    contactFlowId:string;
    isActive: boolean;

    constructor(obj?: any) {
        this.key = obj && obj.key || 0;        
        this.name = obj && obj.name || "";
        this.lineOfBusiness = obj && obj.lineOfBusiness || 0;
        this.contactFlowId = obj && obj.contactFlowId || "";
        this.isActive = obj && obj.isActive || false;
    }

    public static Create(data: any): AmazonConnectContactFlow {
        
        const obj: AmazonConnectContactFlow = new AmazonConnectContactFlow({
            key: data.Key,
            name: data.Name,
            lineOfBusiness: data.LineOfBusiness,
            contactFlowId: data.ContactFlowId,
            isActive: data.IsActive
        });

        return obj;
    }
}
