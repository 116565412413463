import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MentorSpinnerService } from '../service/mentorspinner.service';
import { ChatService } from '../service/chatservice';
import { AdminService } from '../service/admin.service';
import { MaintenanceMode } from '../model/maintenancemode.model';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../service/users.service';

const MINUTES_UNTIL_AUTO_LOGOUT = 240 // in mins
const CHECK_INTERVAL = 1000 // in ms
const STORE_KEY = 'lastAction';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

private CONST_GMSMENTORS_GROUPID: number = 1;
private maintenanceMode: MaintenanceMode;

  constructor(
    private chatService: ChatService, 
    private mspinnerService: MentorSpinnerService,
    private spinnerService: NgxSpinnerService,
    private adminService: AdminService,
    private userService:UsersService,
    public toastr: ToastrService,
    private router:Router) {

    this.adminService.CheckIfMaintenanceModeIsEnabled(this.CONST_GMSMENTORS_GROUPID).subscribe(
        data => {
            this.maintenanceMode = data[0];
            if (!this.maintenanceMode.loginAllowed) {
                this.router.navigate(['/maintenance'], { queryParams: { q: this.CONST_GMSMENTORS_GROUPID } });
            }
    });  

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    localStorage.setItem(STORE_KEY, Date.now().toString());

  }

    async ngOnInit() {
        this.initListener();
        this.initInterval();
        
        this.chatService.connectToSignalR();      
        
        // setTimeout(()=>{ 
        //     this.chatService.ClearPreviousSessions();
        // }, 10000)   
        
        this.mspinnerService.spinnerState.subscribe(val => {
          if (val == true)
            this.spinnerService.show();
          else
            this.spinnerService.hide();
        });

        // AUTOJOIN HERE
    }    
    
    initListener() {
        document.body.addEventListener('click', () => this.reset());
        document.body.addEventListener('mouseover',()=> this.reset());
        document.body.addEventListener('mouseout',() => this.reset());
        document.body.addEventListener('keydown',() => this.reset());
        document.body.addEventListener('keyup',() => this.reset());
        document.body.addEventListener('keypress',() => this.reset());
    }

  reset() {
    this.setLastAction(Date.now());
  }

  setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + (MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000);
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (localStorage.getItem('currentUser') && isTimeout) {
      localStorage.removeItem('lastAction');
      this.chatService.LogOut();
      alert("Your session has expired due to inactivity!");
    }
  }

}

