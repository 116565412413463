export class AmazonConnectSettings {
    id: string;
    description: string;
    instanceId:string;
    tasksCreationEnabled: boolean;

    constructor(obj?: any) {
        this.id = obj && obj.id || '-1';
        this.description = obj && obj.description || "";
        this.instanceId = obj && obj.instanceId || "";
        this.tasksCreationEnabled = obj && obj.tasksCreationEnabled || false;
    }

    public static Create(data: any): AmazonConnectSettings {
        
        const obj: AmazonConnectSettings = new AmazonConnectSettings({
            id: data.Id,
            description: data.Description,
            instanceId: data.InstanceId,
            tasksCreationEnabled: data.tasksCreationEnabled 
        });

        return obj;
    }
}
