import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, NgModule } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AmazonConnectContactFlow } from '../../model/amazonconnectcontactflow.model';

@Component({
  selector: 'app-admin-amazon-connect-contact-flows-add-edit',
  templateUrl: './amazon-connect-contact-flows-add-edit.component.html',
  styleUrls: ['./amazon-connect-contact-flows-add-edit.component.css']
})


export class AmazonConnectContactFlowsAddEditComponent implements OnInit {
  public contactFlow: AmazonConnectContactFlow;
  public disableSaveButton: Boolean;
  public isSlideToggleChecked = false;

  myform: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    lineOfBusiness: new UntypedFormControl('', [Validators.required]),
    contactFlowId: new UntypedFormControl('', [Validators.required]),
    isActive: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router) {
    if (this.route.snapshot.paramMap.get("id") != null) {
      this.getAmazonConnectContactFlowDetails(this.route.snapshot.paramMap.get("id"));
    } else {
      this.contactFlow = new AmazonConnectContactFlow();
    }
  }

  ngOnInit() { }

  getAmazonConnectContactFlowDetails(id: any): void {
    this.adminService.GetAmazonConnectContactFlowById(id).subscribe(data => {
      if (data) {
        this.contactFlow = data[0];

        this.myform.setValue({
          name: this.contactFlow.name,
          lineOfBusiness: this.contactFlow.lineOfBusiness,
          contactFlowId: this.contactFlow.contactFlowId,
          isActive: this.contactFlow.isActive,
        });

        this.isSlideToggleChecked = this.contactFlow.isActive;
      } else {
        this.router.navigateByUrl('admin/amazonconnectcontactflows'); // no messing with query parameters!
      }
    });
  }

  onCloseClick() {
    this.router.navigateByUrl('admin/amazonconnectcontactflows');
  }

  onSaveClick(model: any) {
      if (this.myform.invalid) {
      return;
    }

    this.disableSaveButton = true;
    var newContactFlow = new AmazonConnectContactFlow();

    newContactFlow.key = this.contactFlow.key;
    newContactFlow.name = model.name;
    newContactFlow.lineOfBusiness = model.lineOfBusiness;
    newContactFlow.contactFlowId = model.contactFlowId;
    newContactFlow.isActive = model.isActive;

    if (this.contactFlow.key != 0) {
      this.adminService.UpdateAmazonConnectContactFlow(newContactFlow)
        .subscribe(data => {
          this.disableSaveButton = false;
          alert("Changes successfuly saved!");
        },
          error => {
            this.disableSaveButton = false;
            console.log();
            alert(error.error);
          });
    }
    else {
      this.adminService.CreateAmazonConnectContactFlow(newContactFlow)
        .subscribe(data => {
          this.disableSaveButton = false;
          alert("Successfuly saved!");
          this.router.navigateByUrl('admin/amazonconnectcontactflows');
        },
          error => {
            this.disableSaveButton = false;
            console.log(error.error);
            alert(error.error);
          });
    }
  }
}

