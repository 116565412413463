
import { debounceTime, flatMap, mergeAll, mergeMap, tap, toArray } from 'rxjs/operators';
import { Injectable, NgZone, SecurityContext } from '@angular/core';
import { Message } from '../model/message.model';
import { HttpClient } from '@angular/common/http';
import { MessagesService } from './messages.service';
import { AuthenticationService } from './authentication.service';
import { RoomsService } from './rooms.service';
import { UsersService } from './users.service';
import { TicketService } from './ticket.service';
import { User } from '../model/user';
import { UserFactory } from '../model/userFactory';
import { RoomFactory } from '../model/RoomFactory';
import { Room } from '../model/room.model';
import { messageFactory } from '../model/messageFactory';
import { MessageType } from '../model/messageType';
import { Client } from '../model/client.model';
import { ClientFactory } from '../model/ClientFactory';
import { UsersInfo } from '../model/usersInfo';
import { Subject, Observable, BehaviorSubject, from, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { RhubService, ConnectionState } from './rhub.service';
import { ErrorLogService } from './error-log.service';
import * as _ from 'lodash';
import { AppConfig } from '../AppConfig';
import { IssueService } from './issueservice';
import { SignalRStatus } from '../model/SignalRStatus';
import { ChatUser } from '../model/chatuser.model';
import { createTicket } from '../model/createTicket.model';
import { TicketDataFactory } from '../model/TicketDataFactory';
import { MessageAttachment } from '../model/MessageAttachment.model';
import { DomSanitizer } from '@angular/platform-browser';
import { TicketData } from '../model/ticketData';


@Injectable()
export class ChatService extends RhubService {

  private tryingToReconnect: boolean = false;
  removingEndUserSubject: Subject<boolean> = new Subject<boolean>();
  connectionSubject: Subject<any> = new Subject<any>();
  UserChatInitialized: Subject<any> = new Subject<any>();
  ClientWindowInitialized: Subject<any> = new Subject<any>();
  UserLeftRoom: Subject<any> = new Subject<any>();
  AddedContact: Subject<number> = new Subject<number>();
  TicketCreated: Subject<number> = new Subject<number>();
  ShowSurveyFlag: Subject<any> = new Subject<any>();
  PreventAutoJoin: Subject<Room>=new Subject<Room>();

  joinRoomSubject: Subject<any> = new Subject<any>();
  GetPreviousMessagesInProgres: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private intervalHolder: any;
  private previousSetRoom: Room = null;
  private previousIsCloackedSet: boolean = false;

  private blockSettingCurrentRoom: boolean = false;

  chatMessageToSendSubject: Subject<string> = new BehaviorSubject<string>("");

  constructor(private http: HttpClient,
    public messagesService: MessagesService, private authenticationService: AuthenticationService, private issueService: IssueService,
    public roomsService: RoomsService, public userService: UsersService, public ticketService: TicketService,
    private errorService: ErrorLogService,
    private ngZone: NgZone, private router: Router, private _http: HttpClient, config: AppConfig, private sanitizer: DomSanitizer, public activatedRoute: ActivatedRoute) {
    super(ngZone, config, authenticationService);


    this.joinRoomSubject.asObservable().pipe(
      debounceTime(500)) //question mark debounce time needed
      .subscribe(data => {

        this.invoke("Join", data.roomId, data.lastId, data.isClocked);

        this.getPrevMessages(data.roomId, false, data.lastId);


      });

  }

  onRoomLoadedGetHistory(roomID: string) {
    localStorage.setItem('selectedRoomToBeOpen', roomID);
    this.getPrevMessages(roomID, true, "-1");
  }

  getPrevMessagesChain(roomID: string, forceReload: boolean, lastMsgId: string) {
    if (localStorage.getItem('selectedRoomToBeOpen') === null || localStorage.getItem('selectedRoomToBeOpen') === roomID) {
      var chainedPromisess =
        this.issueService.GetRoomMessages(RoomFactory.createRoomWithGuid(roomID), lastMsgId)
          .pipe(mergeMap(data => {

            let tmpRoom = RoomFactory.createRoomRestApi(data[1]);
            if (localStorage.getItem('selectedRoomToBeOpen') === null || localStorage.getItem('selectedRoomToBeOpen') === tmpRoom.id) {
              let msgs: Message[] = new Array<Message>();
              for (let entry of _.values(data[2])) {
                msgs.push(messageFactory.createMessageLowercase(entry, tmpRoom, UserFactory.createUserLowercase(entry.user)));
              }
              this.roomsService.currentRoomMsgLoaded.next([tmpRoom.id, lastMsgId]);
              var chainedPromisess2 = from(msgs).pipe(
                mergeMap(module => {
                  if (module.messageAttachments && module.messageAttachments.length > 0) {
                    return from(module.messageAttachments).pipe(
                      mergeMap(attach => {
                        if (attach.ThumbnailUriUrl == '')
                          return this.ticketService.downloadFile(attach.ContainerName, attach.ThumbnailUri).pipe(
                            tap(apiResponse => {
                              let objectURL = URL.createObjectURL(apiResponse);
                              attach.ThumbnailUriUrl = objectURL;

                              //this.sendChangeSignal.emit('send');
                            }, error => {
                              return of('');
                            }))
                        else
                          return of('').pipe(
                            tap(() => { console.warn("already a blob") })
                          );
                      }
                      ),
                      toArray()
                    );
                  }
                  else
                    return of('').pipe(
                      tap(() => { console.warn("already a blob") })
                    );
                }
                ),
                toArray()
              );

              return chainedPromisess2.pipe(
                tap(() => {
                  this.messagesService.addMessages(msgs);
                  msgs = null;
                })
              );
            }
          }
          ));

      return chainedPromisess;
    } else
      return of('');

  }

  public getPrevMessages(roomID: string, forceReload: boolean, lastMsgId: string) {

    if (localStorage.getItem('selectedRoomToBeOpen') === null || localStorage.getItem('selectedRoomToBeOpen') === roomID) {

      const subscriptionIssues = this.issueService.GetRoomMessages(RoomFactory.createRoomWithGuid(roomID), lastMsgId)
        .subscribe(data => {

          let tmpRoom = RoomFactory.createRoomRestApi(data[1]);
          if (localStorage.getItem('selectedRoomToBeOpen') === null || localStorage.getItem('selectedRoomToBeOpen') === tmpRoom.id) {
            let msgs: Message[] = new Array<Message>();
            for (let entry of _.values(data[2])) {
              msgs.push(messageFactory.createMessageLowercase(entry, tmpRoom, UserFactory.createUserLowercase(entry.user)));
            }
            this.roomsService.currentRoomMsgLoaded.next([tmpRoom.id, lastMsgId]);
            from(msgs).pipe(
              mergeMap(module => {
                if (module.messageAttachments && module.messageAttachments.length > 0) {
                  return from(module.messageAttachments).pipe(
                    mergeMap(attach => {
                      if (attach.ThumbnailUriUrl == '')
                        return this.ticketService.downloadFile(attach.ContainerName, attach.ThumbnailUri).pipe(
                          tap(apiResponse => {
                            let objectURL = URL.createObjectURL(apiResponse);
                            attach.ThumbnailUriUrl = objectURL;
                            //this.sendChangeSignal.emit('send');
                          }))
                      else
                        return of('').pipe(
                          tap(() => { console.warn("already a blob") })
                        );
                    }
                    ),
                    toArray()
                  );
                }
                else
                  return of('').pipe(
                    tap(() => { console.warn("already a blob") })
                  );
              }
              ),
              toArray()
            ).subscribe(
              () => {
                this.messagesService.addMessages(msgs);


                msgs = null;
              }
            )


          }

        });

    }
  }


  public connectToSignalR(): void {
    this.initRHub();
    this.registerEventHandlers();
    //this.startRHub();


    // this.hubConnection.connectionSlow(function () {
    //   console.log('Slow connection'); // Your function to notify user.
    // });
    this.connectionStateChange.subscribe((connState: any) => {
      if (this.intervalHolder)
        clearInterval(this.intervalHolder);

      switch (connState) {
        case ConnectionState.Connected:

          this.connectionSubject.next(SignalRStatus.Connected);
          if (this.tryingToReconnect)
            this.tryingToReconnect = false;
          else {

            console.log('Connected to teckrx');
            let currUser = JSON.parse(localStorage.getItem('currentUser'));
            this.userService.currentUser.next(currUser);


            if (currUser.hasMentorAccess == 'false' || currUser.hasMentorAccess == undefined)
              this.ClientWindowInitialized.next(true);

            if (localStorage.getItem('previousTicketId')) {
              this.StartHangoutAssociateTicket(localStorage.getItem('previousTicketId'));
              localStorage.removeItem('previousTicketId');
              localStorage.removeItem('previousTicketOpen');

            }
            else {
              localStorage.removeItem('previousTicketId');
              localStorage.removeItem('previousTicketOpen');
              this.StartHangout();
            }

          }
          break;
        case ConnectionState.Reconnecting:
          this.connectionSubject.next(SignalRStatus.Reconnectiong);
          console.log("Reconnecting...");
          this.tryingToReconnect = true;
          break;

        case ConnectionState.Disconnected:
          console.log("Disconnected");

          //if (this.tryingToReconnect) {
          this.intervalHolder = setInterval(() => {
            console.log("stop connections");
            this.startRHub();
            this.connectionSubject.next(SignalRStatus.Disconnected);

            //location.reload();
          }, 5000); // Restart connection after 5 seconds.
          //}
          break;
        default:
          this.tryingToReconnect = false;
          break;
      }
    });

    this.connectionError.subscribe((err: any) => {

      this.errorService.logError(err);
    });

  }

  private registerEventHandlers(): void {
    this.listenAsync('messageReceived', (serverMessage) => this.onMessageReceived(serverMessage));
    this.listenAsync('confirmTicketCreated', (ticketCreatedStatus) => this.onConfirmTicketCreated(ticketCreatedStatus));
    this.listenAsync('contactAdded', (contactId) => this.onContactAdded(contactId));
    this.listenAsync('AddMessage', (messageVM, roomVM) => this.onAddMessage(messageVM, roomVM));
    this.listenAsync('roomsSync', (data, currRoomGuid, syncFirstMessages) => this.onRoomsSync(data, currRoomGuid, syncFirstMessages));
    this.listenAsync('notification', (messageVM, roomVM) => this.onNotification(messageVM, roomVM));
    this.listenAsync('roomMsgHistoryReset', (messages, room) => this.onRoomMsgHistoryReset(messages,
      room));
    this.listenAsync('roomMsgHistory', (messages, room) => this.onRoomMsgHistory(messages, room));
    this.listenAsync('setTyping', (userVM, roomId) => this.onSetTyping(userVM, roomId));
    this.listenAsync('cancelTyping', (userVM, roomId) => this.onCancelTyping(userVM, roomId));
    this.listenAsync('setCurrentRoom', (roomVM) => this.onSetCurrentRoom(roomVM));
    this.listenAsync('setCurrentRoomOnLeave', (roomVM, roomGuid) =>
      this.onSetCurrentRoomOnLeave(roomVM, roomGuid));
    this.listenAsync('setCurrentUser', (userVM) => this.onSetCurrentUser(userVM));
    this.listenAsync('onCloseRoom', (leaveRoomId, authorId, messageVM, roomVM) => this.onCloseRoom(leaveRoomId, authorId, messageVM, roomVM));
    this.listenAsync('destroyRoom', (leaveRoomId) => this.onDestroyRoom(leaveRoomId));
    this.listenAsync('clientLeftRoom', (leaveRoomId, userId) => this.onClientLeftRoom(leaveRoomId, userId));
    this.listenAsync('leaveRoom', (leaveRoomId, userId) => this.onLeaveRoom(leaveRoomId, userId));
    this.listenAsync('updateRoom', (roomId, userId) => this.onUpdateRoom(roomId, userId));
    this.listenAsync('clientIsInitialized', () => this.onClientIsInitialized());
    this.listenAsync('handleException', () => this.onHandleException());
    this.listenAsync('logOutInstance', () => this.onLogOutInstance());
    this.listenAsync('logOutClient', () => this.onLogOutClient());
    this.listenAsync('showUserInfo', (user, client, statusType, clientId) => this.onShowUserInfo(user, client, statusType, clientId));
    this.listenAsync('providedCurrentTranscript', (messages) => this.onProvidedCurrentTranscript(messages));
    this.listenAsync('setCurrentTicket', (ticketVM, roomVM) => this.onSetCurrentTicket(ticketVM, roomVM));
    this.listenAsync('sendTicketNumbers', (ticketArray) => this.sendTicketNumbers(ticketArray));
    this.listenAsync('providedAllTranscripts', (rooms, fullLen, currentPage, user) => this.onProvidedAllTranscripts(rooms, fullLen, currentPage, user));
    this.listenAsync('sendSurveyAndLogoutUser', (roomVM) => this.onSendSurveyAndLogoutUser(roomVM));
    this.listenAsync('emailTranscriptError', () => this.onEmailTranscriptError());
    this.listenAsync('emailTranscriptSuccess', () => this.onEmailTranscriptSuccess());
    this.listenAsync('clearActiveSession', () => this.onClearActiveSession());
    this.listenAsync('reloadPage', () => this.onReloadPage());
    this.listenAsync('showSurveyAndLogout', () => this.onShowSurveyAndLogout());
    this.listenAsync('logOutAndReload', () => this.onLogOutAndReload());
    this.listenAsync('getAllConnectedClientsFromLocalStorage', (roomId) => this.onGetAllConnectedClientsFromLocalStorage(roomId));
    this.listenAsync('addConnectionIdToLS', (connectionId) => this.onAddConnectionIdToLS(connectionId));
    this.listenAsync('getRoomHistory', (roomID) => this.onRoomLoadedGetHistory(roomID));
    this.listenAsync('updateRoomMembers', (chatUsers, roomGuid) => this.onUpdateRoomMembers(chatUsers, roomGuid));
    this.listenAsync('onAutoJoinRoom', (roomVM) => this.onAutoJoinRoom(roomVM));

  }
  onUpdateRoomMembers(chatUsers: any, roomGuid: string): void {
    this.roomsService.updateRoomMembers.next({ chatUsers: chatUsers, roomId: roomGuid });
  }

  /////////////////////////////////////////////////////////////////////////////



  //DONE
  public async StartHangout() {
    var autojoinroomid = this.activatedRoute.snapshot.queryParams["autoJoinRoomById"];
    this.invoke('Hangout', '', '', autojoinroomid);
  }

  public async StartHangoutAssociateTicket(ticketNumber: string) {
    this.invoke('HangoutAssociateWithPreviousTicket', ticketNumber);
  }

  onSetCurrentTicket(ticketVM: any, roomVM: any) {
    this.ticketService.setCurrentTicket(TicketDataFactory.createTicket(ticketVM));
  }

  sendTicketNumbers(ticketArray: Array<any>) {
    let ticketDataArray: TicketData[] = new Array<TicketData>();
    for (let entry of ticketArray) {
      ticketDataArray.push(TicketDataFactory.createTicket(entry));
    }
    this.ticketService.sendTicketNumbers(ticketDataArray);
  }

  public async updateTicket(
    ticketList: createTicket[], IsPreviousTicket: boolean) {

    await this.invoke("UpdateTicket", ticketList, IsPreviousTicket);
  }

  public async createNewTicket(ticketList: createTicket[], IsPreviousTicket: boolean) {

    await this.invoke("CreateNewTicket", ticketList, IsPreviousTicket);
  }


  onConfirmTicketCreated(ticketCreatedStatus: number) {
    this.TicketCreated.next(ticketCreatedStatus);
  }

  public async addNewContact(clientId: string, firstName: string, lastName: string, email: string) {
    await this.invoke("CreateContact", clientId, firstName, lastName, email);
  }

  onContactAdded(contactId: number) {
    this.AddedContact.next(contactId);
  }

  //DONE
  public async sendMessage(message: Message) {
    this.messagesService.addMessage(message);
    //await this.hubWrapper.invoke("SendMessage", message.room.id, message.text, message.isPrivate).toPromise();
    await this.invoke("SendMessage", message.room.id, message.id, message.text, message.isPrivate, message.messageAttachments, null);
  }



  //DONE
  public async sendInitMessage(message: Message) {
    this.messagesService.addMessage(message);
    //await this.hubWrapper.invoke("SendMessage", message.room.id, message.text, message.isPrivate).toPromise();
    await this.invoke("SendInitMessage", message.room.id, message.id, message.text, message.isPrivate);
  }

  public async sendPleaseWaitMessage(message: Message) {
    //this.messagesService.addMessage(message);
    //await this.hubWrapper.invoke("SendMessage", message.room.id, message.text, message.isPrivate).toPromise();
    await this.invoke("SendPleaseWaitMessage", message.room.id, message.id, message.text);
  }

  public async sendCourseNameMessage(message: Message) {
    //this.messagesService.addMessage(message);
    //await this.hubWrapper.invoke("SendMessage", message.room.id, message.text, message.isPrivate).toPromise();
    await this.invoke("SendCourseNameMessage", message.room.id, message.id, message.text);
  }

  public async sendHostNameMessage(message: Message) {
    await this.invoke("SendHostNameMessage", message.room.id, message.id, message.text);
  }


  onAddMessage(messageVM: any, roomVM: any) {

    let usr: User = UserFactory.createUser(messageVM.User);
    let rvm: Room = RoomFactory.createRoom(roomVM);
    let msg = messageFactory.createMessage(messageVM, rvm, usr);


    from(msg.messageAttachments).pipe(
      mergeMap(module => {
        if (module.ThumbnailUriUrl == '')
          return this.ticketService.downloadFile(module.ContainerName, module.ThumbnailUri).pipe(
            tap(apiResponse => {
              let objectURL = URL.createObjectURL(apiResponse);
              module.ThumbnailUriUrl = objectURL;
              //this.sendChangeSignal.emit('send');
            }))
        else
          return of('').pipe(
            tap(() => { console.warn("already a blob") })
          );
      }
      ),
      toArray()
    ).subscribe(allResponses => {
      this.messagesService.addMessage(msg);

    });

    //if (messageVM.MessageType !== MessageType.Notification) {
    if (this.onSetCurrentRoom) {
      this.userService.currentUser.subscribe((currUser) => {

        if (currUser.id !== usr.id && (msg.msgType == MessageType.Default || msg.msgType == MessageType.Notification || msg.msgType == MessageType.System
          || msg.msgType == MessageType.Private || msg.msgType == MessageType.MentorSystem))
          this.messagesService.newMessageNotification.next(roomVM.RoomGuid);
      });
    }
    this.messagesService.cancelTypingNotification(usr);
    //}
  }

  private async Reset(roomId: string) {
    this.messagesService.clearNotActiveRooms(roomId);
  }




  onRoomsSync(data: any, currRoomGuid: any, roomsFirstMessages: any) {

    this.Reset(currRoomGuid);

    let msgs: Message[] = new Array<Message>();



    setTimeout(() => {
      /*Your Code*/
      data.forEach(syncData => {
        let room: Room = RoomFactory.createRoom(syncData.roomVM);
        let usr: User = UserFactory.createUser(syncData.messageVM.User);
        let msg = messageFactory.createMessage(syncData.messageVM, room, usr);

        msgs.push(msg);

        //this.messagesService.addMessage(msg);

        if (msg.msgType == MessageType.Default || msg.msgType == MessageType.MentorSystem) {
          this.messagesService.newMessageNotification.next(room.id);
          this.messagesService.cancelTypingNotification(usr);
        }

      });

      this.messagesService.addMessages(msgs);

    }, 100);


    let lastMsgs: Message[] = new Array<Message>();

    roomsFirstMessages.forEach(syncData => {
      let room: Room = RoomFactory.createRoom(syncData.roomVM);
      let usr: User = UserFactory.createUser(syncData.messageVM.User);
      let msg = messageFactory.createMessage(syncData.messageVM, room, usr);

      lastMsgs.push(msg);
    });
    this.messagesService.roomsLatestMessagesChange.next(lastMsgs);
  }



  onNotification(messageVM: any, roomVM: any) {
    const me: User = UserFactory.createUser(messageVM.User);
    const theRoom: Room = RoomFactory.createRoom(roomVM);
    this.messagesService.addMessage(
      messageFactory.createMessage(messageVM, theRoom, me)
    );

  }

  public async getPreviousMsgs(roomGuid: string, lastMsgGuid: string) {
    await this.invoke("GetPreviousMessages", roomGuid, lastMsgGuid);
  }


  onRoomMsgHistoryReset(messages: any, room: any) {
    //this.Reset(room.RoomGuid);
    this.onRoomMsgHistory(messages, room);
  };



  onRoomMsgHistory(messages: any, room: any) {

    let tmpRoom = RoomFactory.createRoom(room);

    if (localStorage.getItem('selectedRoomToBeOpen') === null || localStorage.getItem('selectedRoomToBeOpen') === tmpRoom.id) {

      let msgs: Message[] = new Array<Message>();

      for (let entry of messages) {
        msgs.push(messageFactory.createMessage(entry, tmpRoom, UserFactory.createUser(entry.User)));
      }
      this.messagesService.addMessages(msgs);

      this.roomsService.currentRoomMsgLoaded.next([tmpRoom.id, '-1']);
      msgs = null;
    }


  };



  public async StartTyping(roomId: string, isPrivate: boolean) {
    await this.invoke("Typing", roomId, isPrivate);
  }


  onSetTyping(userVM: any, roomId: any) {

    const me: User = UserFactory.createUser(userVM);

    this.roomsService.currentRoom.subscribe(r => {
      if (r.id === roomId) {
        this.messagesService.addTypingNotification(me);
        roomId = 1;
      }
    });

  }


  onCancelTyping(userVM: any, roomGuid: any) {
    this.messagesService.cancelTypingNotification(UserFactory.createUser(userVM));
  }


  //DONE

  onSetCurrentRoom(roomVM: any) {
    if (localStorage.getItem('selectedRoomToBeOpen') === null || localStorage.getItem('selectedRoomToBeOpen') === roomVM.RoomGuid || roomVM.Name == "Lobby") {
      this.roomsService.setCurrentRoom(RoomFactory.createRoom(roomVM));
      localStorage.setItem('selectedRoomToBeOpen', roomVM.RoomGuid);
    }
  }

  private tmpRoomGuid: string = "";

  onSetCurrentRoomOnLeave(roomVM: any, roomGuid: string) {

    //this.roomsService.setCurrentRoom(RoomFactory.createRoom(roomVM));
    this.tmpRoomGuid = roomGuid;
    localStorage.setItem('selectedRoomToBeOpen', roomGuid);
    this.roomsService.setCurrentRoom(RoomFactory.createRoom(roomVM));

    this.tmpRoomGuid = "";
  }


  onSetCurrentUser(userVM: any) {
    this.userService.setCurrentUser(UserFactory.createUser(userVM))
    localStorage.setItem('currAuthor', JSON.stringify(UserFactory.createUser(userVM)));
  }


  public async closeRoom(currentRoom: Room) {
    console.log("Close room " + currentRoom.id);
    await this.invoke("CloseRoom", currentRoom.id);
  }

  public async closeChatPopup(roomId: string) {
    await this.invoke("closeChatPopup", roomId);
  }

  // @HubSubscription()
  // ClientLeftChat(){
  //     this.userService.ClientLeftRoom();
  // }

  onCloseRoom(leaveRoomId: string, authorId: string, messageVM: any, roomVM: any) {
    const me: User = UserFactory.createUser(messageVM.User);
    const theRoom: Room = RoomFactory.createRoom(roomVM);
    this.messagesService.markMsgsAsClosedForRoom(leaveRoomId, authorId, messageFactory.createMessage(messageVM, theRoom, me));
  }

  onDestroyRoom(leaveRoomId: string) {
    //FNOTE: this should be called after popup is closed
    this.messagesService.deleteMessageForRoom(leaveRoomId);
  }


  onClientLeftRoom(leaveRoomId: string, userId: string) {
    this.UserLeftRoom.next(true);
  }


  onLeaveRoom(leaveRoomId: string, userId: string) {
    // this.UserLeftRoom.next(true);
    this.messagesService.leaveRoom(leaveRoomId, userId);
    this.removingEndUserSubject.next(false);
  }





  public joinRoom(room: Room, isClocked: boolean) {
    let lastId: string = '0'

    this.previousSetRoom = room;
    this.previousIsCloackedSet = isClocked;

    if (!this.blockSettingCurrentRoom && room.closed == 0) {
      localStorage.setItem('selectedRoomToBeOpen', room.id);
      this.joinRoomSubject.next({ roomId: room.id, lastId: lastId, isClocked: isClocked });
    }

  }

  setBlockSettingCurrentRoom(value: boolean) {
    this.blockSettingCurrentRoom = value;
  }

  unblockSetCurrentRoom(): void {

    this.setBlockSettingCurrentRoom(false);
    if (this.roomsService.getPreviousRoomSet() != null)
      this.joinRoom(this.roomsService.getPreviousRoomSet(), this.previousIsCloackedSet);
  }


  getPreviousRoomSet(): Room {
    if (this.previousSetRoom != null)
      return this.previousSetRoom;

    return null;
  }



  onUpdateRoom(roomId: string, userId: String) {
    this.messagesService.markMsgsAsAttendedForRoom(roomId, userId);
  }


  onClientIsInitialized() {
    this.UserChatInitialized.next(true);
  }


  onHandleException() {

    //run inside zone to update UI        
    this.ngZone.run(() => {
      //FTODO: uncomment this
      // this.hubWrapper.invoke("LogOut").toPromise();
      // this.hubService.disconnect();
      // this.hubWrapper.unregister();
      this.disconnect();
      this.authenticationService.logout();
      window.location.replace('/logout');
      this.router.navigate(['login']);
    });
  }


  onLogOutInstance() {
    this.authenticationService.logout();

    window.location.replace('/logout');
    this.router.navigate(['login']);
  }

  LogOut() {
    this.invoke("LogOut");
    this.messagesService.clearAllRoomsMessages('-1');
    this.router.navigate(['login']);
    //window.location.reload();
  }


  onLogOutClient() {
    var clientParams = JSON.parse(localStorage.getItem('clientParams'));

    this.disconnect();
    this.authenticationService.logout();
    // this.hubService.disconnect();
    // this.hubWrapper.unregister();
  }


  public async getUserInfo(userId: string) {
    await this.invoke("SendUserAndClientInfo", userId);
  }

  onShowUserInfo(user: User, client: Client, statusType: string, clientId: number) {
    client.status = statusType;
    this.userService.sendUserInfo(new UsersInfo(UserFactory.createUser(user), ClientFactory.createClient(client), clientId));
  }


  /////////////////////////// API - TOMCHE - najbrzo so mozesh 

  public async getCurrentTranscript(userId: string, roomId: string) {
    await this.invoke("SendCurrentTranscript", userId, roomId);
  }

  onProvidedCurrentTranscript(messages: any) {
    var currentTranscript = [];
    for (var i = 0; i < messages.length; i++) {
      currentTranscript.push(messageFactory.createMessagev2(messages[i]));
    }
    this.messagesService.showCurrentTranscript(currentTranscript);
  }

  public async getTranscriptForRoom(roomId: string, lastMessageId: string) {

    await this.invoke("GetPreviousMessages", roomId, '');
  }

  public async getTicketInfo(roomId: string) {

    await this.invoke("GetTicketInfo", roomId);
  }




  onProvidedAllTranscripts(rooms: any, fullLen: number, currentPage: number, user: User) {
    var allTranscripts = [];
    for (var i = 0; i < rooms.length; i++) {
      allTranscripts.push(RoomFactory.createRoom(rooms[i]));
    }
    this.roomsService.showAllTranscript(allTranscripts, fullLen, currentPage, UserFactory.createUser(user));
  }

  //////////////////////////////////////////////////

  public async RemoveUser(userId: string, roomId: string) {
    this.removingEndUserSubject.next(true);
    console.log("Remove User " + roomId + " " + userId);
    await this.invoke("RemoveUser", userId, roomId);
  }

  public async BanUser(userId: string, roomId: string) {
    await this.invoke("BanUser", userId, roomId);
  }

  public async SendSurveyToUser(userId: string, roomId: string) {
    await this.invoke("SendSurveyToUser", userId, roomId);
  }


  onSendSurveyAndLogoutUser(roomVM: Room) {
    this.ShowSurveyFlag.next(true);
    //this.closeRoom(RoomFactory.createRoom(roomVM));
    this.authenticationService.logout();
  }

  public async SendEmailTranscript(user: User, room: Room) {
    await this.invoke("SendEmailTranscript", user.id, room.id);
  }


  onEmailTranscriptError() {
    this.userService.emailTransciptErr.next();
  }


  onEmailTranscriptSuccess() {
    this.userService.emailTranscriptSuccess.next();
  }

  public async ClearPreviousSessions() {
    await this.invoke("ClearPreviousSessions");
  }


  onClearActiveSession() {
    this.authenticationService.logout();
  }


  onReloadPage() {
    window.location.reload();
  }

  ShowSurveyWindow() {
    this.ShowSurveyFlag.next(true);
  }


  onShowSurveyAndLogout() {
    this.ShowSurveyFlag.next(true);
    this.authenticationService.logout();
    this.disconnect();
    // this.hubService.disconnect();
    // this.hubWrapper.unregister();
  }




  onLogOutAndReload() {
    this.authenticationService.logout();
    this.disconnect();
    // this.hubService.disconnect();
    // this.hubWrapper.unregister();
    window.location.reload()
  }


  onGetAllConnectedClientsFromLocalStorage(roomId: string) {
    var connectionIds = localStorage.getItem("allConnectionIds");
    localStorage.removeItem("allConnectionIds");
    var restConIdAfterDelete = localStorage.getItem("allConnectionIds");
    this.LogoutUserFromAllTabs(connectionIds, roomId);
  }

  public async LogoutUserFromAllTabs(connectionIds: string, roomId: string) {
    await this.invoke("LogoutAllByConnectionId", connectionIds, roomId);
  }


  onAddConnectionIdToLS(connectionId: string) {
    // localStorage.setItem("allConnectionIds", connectionId);
    var connectionIds = localStorage.getItem("allConnectionIds");
    if (connectionIds == null) {
      connectionIds = "";
    }
    connectionIds = connectionIds + connectionId + ";";
    localStorage.setItem("allConnectionIds", connectionIds);
  }




  ///////////////////////////////////////////
  private broadcastMessage(): void {
    this.invoke('NotificationService', 'text message');
  };

  private onMessageReceived(serverMessage: string) {
    console.log('New message received from Server: ' + serverMessage);
  }

  ngOnDestroy() {
    if (this.joinRoomSubject) {
      this.joinRoomSubject.unsubscribe();
    }
  }

  public setCurrentChatMessage(chatMessage: string): void {
    this.chatMessageToSendSubject.next(chatMessage);
  }

  onAutoJoinRoom(roomVM: any) {
    var room = RoomFactory.createRoom(roomVM);

    if (room.mentorCount > 0){
      this.PreventAutoJoin.next(room);
    }
    else{
      this.joinRoom(room, false);
      this.onSetCurrentRoom(roomVM);
    }

    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { }});
  }
}
