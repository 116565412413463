<h2>Amazon Connect Contact Flows</h2> <br />

<mat-spinner *ngIf="showSpinner == true" color="warn" mode="indeterminate" style="margin: 0 auto !important;">
                     
</mat-spinner>

<div>
        <div>
                <br />
                <div>
                        <div class="button-row" style="float:right;">
                                <a routerLink="./add">
                                        <button mat-stroked-button>Create New</button>
                                </a>
                        </div>
                </div>

                <br />

                <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="name">
                                <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                                <mat-cell *matCellDef="let contactflow" style="max-width: 300px !important;"> {{contactflow.name}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="lineOfBusiness">
                                <mat-header-cell *matHeaderCellDef> Line of business </mat-header-cell>
                                <mat-cell *matCellDef="let contactflow" style="max-width: 245px !important;">
                                        {{getLineOfBusiness(contactflow.lineOfBusiness)}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="contactFlowId">
                                <mat-header-cell *matHeaderCellDef> Contact Flow Id </mat-header-cell>
                                <mat-cell *matCellDef="let contactflow"> {{contactflow.contactFlowId}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="status">
                                <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
                                <mat-cell *matCellDef="let contactflow">
                                        <div *ngIf="contactflow.isActive == true">
                                                <p style="color:rgb(44, 194, 44);">Active</p>
                                        </div>
                                        <div *ngIf="contactflow.isActive == false">
                                                <p style="color:rgb(245, 2, 2);">Inactive</p>
                                        </div>
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let contactflow">
                                        <a routerLink="./edit/{{contactflow.key}}">
                                                <button mat-stroked-button>
                                                        Edit
                                                </button>
                                        </a>
                                </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="delete">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let contactflow">
                                        <a (click)="onDeleteClick(contactflow.key)"><button
                                                        mat-stroked-button>Delete</button>
                                        </a>
                                </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                </mat-table>
                <mat-paginator #paginator (page)="onPageChanged($event)" [pageSizeOptions]="[5,10,25]" [pageSize]="5"
                        [length]="fullLength">
                </mat-paginator>
        </div>
</div>

<div>
        <router-outlet></router-outlet>
</div>