<div class="clocked-popup-wrapper">

<mat-dialog-actions>
<button class="mat-raised-button close" (click)="cancel()"> X </button>
</mat-dialog-actions>

<h2 mat-dialog-title></h2>
<div class="mat-dialog-question">{{warningMessage}}</div>

<mat-dialog-actions>
<button class="mat-raised-button mat-primary" (click)="ok()">Dismiss</button>
<!-- <button class="mat-raised-button" (click)="close()">No</button>
<button class="mat-raised-button mat-warn" *ngIf="showCancelButton| async" (click)="cancel()">Cancel</button> -->
</mat-dialog-actions>

</div>
