import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ComponentItem } from '../../home/chat-panel/componentItem';
import { CompHolderDirective } from '../../home/chat-panel/comp-holder.directive';
import { ConfirmComponent } from '../../home/confirm/confirm.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { AmazonConnectContactFlow } from '../../model/amazonconnectcontactflow.model';

@Component({
  selector: 'app-admin-amazon-connect-contact-flows',
  templateUrl: './amazon-connect-contact-flows.component.html',
  styleUrls: ['./amazon-connect-contact-flows.component.css']
})

export class AmazonConnectContactFlowsComponent implements OnInit {
  defaultPageNumber: number = 1;
  defaultPageSize: number = 100;
  allItems: any[] = [];
  perPage: number = 10;
  fullLength: number;
  currentPage: number;
  totalPages: number;
  public userId: string;
  dataSource: AmazonContactFlowsDataSource;
  displayedColumns = ["name", "lineOfBusiness", "contactFlowId", "status", "edit", "delete"];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild(CompHolderDirective, { static: true }) adHost: CompHolderDirective;

  menuComponents: ComponentItem[];
  public showParentComponent: Boolean = true;
  public showChildComponent: Boolean;
  public showSpinner: Boolean;

  constructor(private adminService: AdminService, private dialog: MatDialog) {

  }

  ngOnInit() {
    this.ngOnInitPage(); 
  }

  ngOnInitPage() {
    this.dataSource = new AmazonContactFlowsDataSource(this.adminService);
    this.dataSource.loadData(this.defaultPageNumber, this.defaultPageSize);
  }

  onDeleteClick(contactFlowKey: any) {
    this.openDialogDelete(contactFlowKey);
  }

  openDialogDelete(contactFlowKey: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    var warningMessage = "Are you sure you want to delete the contact flow? Note that this will disrupt tasks creation in Symbee Connect!";

    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '300px',
      width: '400px',
      data: { warningMessage: warningMessage }
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.showSpinner = true;
          this.adminService.DeleteAmazonConnectContactFlow(contactFlowKey).subscribe(data => {
            this.dataSource.loadData(this.defaultPageNumber, this.defaultPageSize);
            this.showSpinner = false;
          });
        }
      }
    );
  }

  onPageChanged(paginator: any) {
    this.dataSource.loadData(paginator.pageIndex + 1, paginator.pageSize);
  }

  getLineOfBusiness(lineOfBusiness: number): string | undefined {
    switch (lineOfBusiness) {
      case LineOfBusiness.Telco:
        return "TELCO";
      case LineOfBusiness.Msp:
        return "MSP";
      case LineOfBusiness.Edu:
        return "EDU";
      default:
        return undefined;
    }
  }
}

enum LineOfBusiness {
  Telco = 1,
  Msp = 2,
  Edu = 3
}

export class AmazonContactFlowsDataSource implements DataSource<any> {
  private subject = new BehaviorSubject<AmazonConnectContactFlow[]>([]);
  constructor(private adminService: AdminService) { }

  connect(collectionViewer: CollectionViewer): Observable<AmazonConnectContactFlow[]> {
    return this.subject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer) {
    this.subject.complete();
  }

  loadData(pageIndex: number, pageSize: number) {
    this.adminService.GetAmazonConnectContactFlows(pageIndex, pageSize).subscribe(data => {
      this.subject.next(data);
    });
  }
}

