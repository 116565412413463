<br /><br />

<form *ngIf="contactFlow" class="example-form" name="form" (ngSubmit)="disableSaveButton || onSaveClick(myform.value)" [formGroup]="myform">

  <mat-form-field class="example-full-width" *ngIf="contactFlow.key != 0">
    <input style="font-weight: bold;" matInput disabled placeholder="ID" value="{{contactFlow.key}}">
  </mat-form-field>

  <div class="form-group">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Name" formControlName="name">
    </mat-form-field>
    <div class="validation-errors">
      <div class="error-message"
        *ngIf="myform.get('name').hasError('required') && (myform.get('name').dirty || myform.get('name').touched)">Name
        is required field!</div>
    </div>
  </div>

  <div class="form-group">
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Contact Flow ID" formControlName="contactFlowId">
    </mat-form-field>
    <div class="validation-errors">
      <div class="error-message"
        *ngIf="myform.get('contactFlowId').hasError('required') && (myform.get('contactFlowId').dirty || myform.get('contactFlowId').touched)">
        Contact Flow ID is required field!</div>
    </div>
  </div>

  <div class="form-group">
    <mat-form-field>
      <mat-select 
      placeholder="Line of business" 
      name="lineOfBusiness"  
      formControlName="lineOfBusiness"
      [(value)]="contactFlow.lineOfBusiness" 
      [(ngModel)]="contactFlow.lineOfBusiness"
      title="Line of business">
        <mat-option [value]="1">TELCO</mat-option>
        <mat-option [value]="2">MSP</mat-option>
        <mat-option [value]="3">EDU</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <div class="form-group">
      <mat-card-content>
        <p>Is Active</p>
        <section class="example-section">
          <mat-slide-toggle matInput 
          formControlName="isActive" 
          [(ngModel)]="isSlideToggleChecked" 
          name="isActive"
          class="example-margin">
          </mat-slide-toggle>
        </section>
      </mat-card-content>  
    </div>

  <br />

  <div class="button-row">
    <button mat-stroked-button type="submit">Save</button> &nbsp;
    <button mat-stroked-button color="warn" (click)="onCloseClick()">Close</button>
  </div>

</form>