<div id="wrapper">

        <nav id="sidebar" class="active">
            
            <ul class="list-unstyled components">
    
                <li>
                    <a routerLink="/admin/mentors">
                        <i class="glyphicon glyphicon-user"></i> Mentors Management
                    </a>
                </li>

                <li>
                    <a routerLink="/admin/users">
                        <i class="glyphicon glyphicon-user"></i> Chat Users Management
                    </a>
                </li>
    
                <li>
                    <a routerLink="/admin/cannedresponses">
                        <i class="glyphicon glyphicon-th"></i> Canned Responses
                    </a>
                </li>
                
                <li>
                    <a routerLink="/admin/amazonconnectsettings">
                        <i class="glyphicon glyphicon-cloud"></i> Amazon Connect Settings
                    </a>
                </li>

                <li>
                    <a routerLink="/admin/amazonconnectcontactflows">
                        <i class="glyphicon glyphicon-headphones"></i> Amazon Connect Contact Flows
                    </a>
                </li>

                <li>
                    <a routerLink="/admin/settings">
                        <i class="glyphicon glyphicon-cog"></i> User Settings
                    </a>
                </li>
                    
                <li>
                        <a (click)="logout()">
                            <i class="glyphicon glyphicon-off"></i> LogOut
                        </a>
                </li>
                   
            </ul>
        </nav>       
        
        <div class="adminWindow" id="content">
                <router-outlet></router-outlet>
        </div>
</div>


