import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { ComponentItem } from '../../home/chat-panel/componentItem';
import { CompHolderDirective } from '../../home/chat-panel/comp-holder.directive';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { AmazonConnectSettings } from '../../model/amazonconnectsettings.model';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-amazon-connect-settings',
  templateUrl: './amazon-connect-settings.component.html',
  styleUrls: ['./amazon-connect-settings.component.css']
})

export class AmazonConnectSettingsComponent implements OnInit {

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild('input',{static:false}) input: ElementRef;
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  menuComponents: ComponentItem[];
  public showParentComponent: Boolean = true;
  public showChildComponent: Boolean;
  public showSpinner: Boolean = true;
  myform: UntypedFormGroup;
  public isSlideToggleChecked = false;
  public amazonConnectSettings:AmazonConnectSettings;

  constructor(private adminService:AdminService) { }

   ngOnInit() {
    this.showSpinner = true;

    this.loadSettings();

      this.myform = new UntypedFormGroup({
        description: new UntypedFormControl('', Validators.required),
        instanceId: new UntypedFormControl('', Validators.required),
        tasksCreationEnabled: new UntypedFormControl('', Validators.nullValidator)
      });

      this.showSpinner = false;
    }

  updateSettings(data:any){
    this.adminService.UpdateAmazonConnectSettings(data).subscribe(data => {
      alert(data.toString());
      window.location.reload();
    });
  }

  loadSettings() {
    this.adminService.GetAmazonConnectSettings().subscribe(data => {
       this.amazonConnectSettings = data[0];
       this.isSlideToggleChecked = this.amazonConnectSettings.tasksCreationEnabled;
       this.myform.setValue({
        description: this.amazonConnectSettings.description, 
        instanceId: this.amazonConnectSettings.instanceId,
        tasksCreationEnabled : this.amazonConnectSettings.tasksCreationEnabled
      });
    });
  }
}

