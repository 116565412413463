import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { PendingChangesGuard } from './auth/guards/pendingchanges.guard';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ClientComponent } from './client/client.component';
import { AdminComponent } from './admin/admin.component';
import { AdminUsersManagementComponent } from './admin/user-management/user-management.component';
import { AdminChatUserEditComponent } from './admin/chat-user-edit/chat-user-edit.component';
import { AdminMentorSkillMappingComponent } from './admin/mentor-skill-mapping/mentor-skill-mapping.component';
import { AdminSettingsComponnent } from './admin/settings/settings.component';
import { RegisterChatMspComponent } from './client/register-chat-msp/register-chat-msp.component';
import { AuthGuardAdmin } from './auth/guards/authadmin.guard';
import { AdminMentorAddEditComponent } from './admin/mentor-add-edit/mentor-add-edit.component';
import { AdminMentorsManagementComponent } from './admin/mentor-management/mentor-management.component';
import { PasswordResetComponent } from './auth/passwordreset/passwordreset.component';
import { AuthGuardClient } from './auth/guards/authclient.guard';
import { ClientFeedbackComponent } from './client/client-feedback/client-feedback.component';
import { AdminVouchersManagement } from './admin/vouchers/vouchers.component';
import { AdminVoucherAddEditComponent } from './admin/voucher-add-edit/voucher-add-edit.component';
import { ChatCloseFormComponent } from './home/chat-close-form/chat-close-form.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { AdminCannedResponsesComponent } from './admin/canned-responses/canned-responses.component';
import { AdminCannedResponsesAddEditComponent } from './admin/canned-responses-add-edit/canned-responses-add-edit.component';
import { AmazonConnectSettingsComponent } from './admin/amazon-connect-settings/amazon-connect-settings.component';
import { AmazonConnectContactFlowsComponent } from './admin/amazon-connect-contact-flows/amazon-connect-contact-flows.component';
import { AmazonConnectContactFlowsAddEditComponent } from './admin/amazon-connect-contact-flows-add-edit/amazon-connect-contact-flows-add-edit.component';

const appRoutes: Routes = [
    {
        path: '', component: HomeComponent, canActivate: [AuthGuard]
        , children: [
            {
                path: 'close',
                component: ChatCloseFormComponent,
                canDeactivate: [PendingChangesGuard]
            }]
    },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },

    {
        path: 'client',
        component: ClientComponent,
        canActivate: [AuthGuardClient],
        children: [
            {
                path: 'registerchatmsp',
                component: RegisterChatMspComponent
            }
        ]
    },

    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuardAdmin],
        children: [
            {
                path: '',
                component: AdminComponent
            },

            {
                path: 'mentors',
                component: AdminMentorsManagementComponent
            },

            {
                path: 'mentors/add',
                component: AdminMentorAddEditComponent
            },

            {
                path: 'mentors/edit/:id',
                component: AdminMentorAddEditComponent
            },

            {
                path: 'users',
                component: AdminUsersManagementComponent
            },

            {
                path: 'users/edit/:id',
                component: AdminChatUserEditComponent
            },

            {
                path: 'mappings',
                component: AdminMentorSkillMappingComponent
            },

            {
                path: 'settings',
                component: AdminSettingsComponnent
            },

            {
                path: 'vouchers',
                component: AdminVouchersManagement
            },

            {
                path: 'vouchers/add',
                component: AdminVoucherAddEditComponent
            },

            {
                path: 'vouchers/details/:voucherId/',
                component: AdminVoucherAddEditComponent
            },

            {
                path: 'vouchers/details/:voucherId/:clientName',
                component: AdminVoucherAddEditComponent
            },
            {
                path: 'cannedresponses',
                component: AdminCannedResponsesComponent
            },

            {
                path: 'cannedresponses/add',
                component: AdminCannedResponsesAddEditComponent
            },

            {
                path: 'cannedresponses/edit/:id',
                component: AdminCannedResponsesAddEditComponent
            },

            {
                path: 'amazonconnectsettings',
                component: AmazonConnectSettingsComponent
            },

            {
                path: 'amazonconnectcontactflows',
                component: AmazonConnectContactFlowsComponent
            },

            {
                path: 'amazonconnectcontactflows/add',
                component: AmazonConnectContactFlowsAddEditComponent
            },

            {
                path: 'amazonconnectcontactflows/edit/:id',
                component: AmazonConnectContactFlowsAddEditComponent
            },
        ]
    },

    { path: 'passwordreset', component: PasswordResetComponent },

    // testing purpose only
    { path: 'feedback', component: ClientFeedbackComponent },

    { path: 'maintenance', component: MaintenanceComponent },

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {});


